<template>
  <section :class="backgroundColor +'  pa-10'">
    <v-container>
      <v-row>
        <v-col cols="0" md="1"></v-col>
        <v-col cols="12" md="5">
          <v-responsive :aspect-ratio="16 / 9" id="video-container">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/ioZP0Od21mg"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </v-responsive>
        </v-col>
        <v-col cols="0" md="1"></v-col>
        <v-col cols="12" md="4">
          <app-title class="mb-8">Webinaire de lancement URPS Pharmaciens 03/11/2020</app-title>
          <p>
            Retrouvez dans cette vidéo la présentation des objectifs de
            l’enquête Demandes de soins non programmées en officine ainsi que le
            détail des modalités pratiques pour y participer. Si vous n'avez pu
            participer au webinaire, prenez le temps de la regarder en entier.
            Prêts à participer ? Commencez par inscrire votre officine
          </p>
          <app-btn to="register">Inscription</app-btn>
        </v-col>
        <v-col cols="0" md="1"></v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'WebinarVideoSection',
  components: {
    'app-title': () => import('../../../core/Title'),
    'app-btn': () => import('../../../core/BasicBtn')
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
    backgroundColor() {
      return this.currentUser ? 'white' : 'grey lighten-4'
    }
  }
}
</script>

<style scoped>
#video-container {
  background-color: brown;
  width: 100%;
}
</style>
